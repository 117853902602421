var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("event-storming-model-canvas", {
    ref: "designer",
    staticStyle: { height: "100%", width: "100%" },
    attrs: {
      sliderLocationScale: 0.25,
      isRead: false,
      elementTypes: _vm.elementTypes,
      elementListBeanPath: "classDefinitions",
      relationListBeanPath: "relations",
      relationVueComponentName: "class-relation",
    },
    model: {
      value: _vm.model,
      callback: function ($$v) {
        _vm.model = $$v
      },
      expression: "model",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }